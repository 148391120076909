import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import axios from "axios";

export default class Wizard extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired
  };
  static Page = ({ children }) => children;

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      submitted: false,
      values: props.initialValues || {}
    };
  }
  next = values =>
    this.setState(state => ({
      page: Math.min(state.page + 1, this.props.children.length - 1),
      values
    }));

  previous = () =>
    this.setState(state => ({
      page: Math.max(state.page - 1, 0)
    }));

  complete = () =>
  this.setState(state =>({submitted: true}));

  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  validate = values => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ];
    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  

  handleSubmit = values => {
    // const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    const { children, onSubmit } = this.props;
    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;
    if (isLastPage) {
      //return onSubmit(values);
      var self = this;
       axios.post('https://myhjo7tmvg.execute-api.us-east-2.amazonaws.com/prod/rsvps', values)
      .then(function (response) {
        console.log(response);
        self.complete();
      })
      .catch(function (error) {
          if (error.response &&error.response.data && error.response.data.error) {
            window.alert("Error: "+ error.response.data.error);
          }
            else if (error.response) {
              window.alert("An unknown error occurred submitting your RSVP");
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            window.alert("The request timed out");
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            window.alert("Something happened in setting up the request that triggered an Error");
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
      });
      // return { [FORM_ERROR]: "Login Failed" };
      
      //this.render();
    } else {
      this.next(values);
    }
  };


  render() {
    const { children } = this.props;
    const { page, values, submitted } = this.state;
    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === React.Children.count(children) - 1;

    if (values.accessCode !== "hotdog"){
      delete values.bringingGuest;
    }

    if (!values.bringingGuest){
      delete values.guest;
    }
    if (!submitted)
    return (
    
      <Form
        initialValues={values}
        validate={this.validate}
        onSubmit={this.handleSubmit}
      >
        {({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            {activePage}
            <div className="col-12" style={{paddingTop:'1.5rem'}}>
            <ul class="actions">
            {page > 0 && (
              <li>
                <button type="button" onClick={this.previous}>
                  « Previous
                </button>
              </li>

              )}
              {!isLastPage && (
              <li>
                <button type="submit" className="primary">Next »</button>
              </li>)}
              {isLastPage && (
                <li>
                  <button type="submit" disabled={submitting} className="primary">
                    Submit
                  </button>
                </li>
              )}
            </ul>
            </div>

            {/* <pre>{JSON.stringify(this.state, 0, 2)}</pre>
            <pre>{JSON.stringify(values, 0, 2)}</pre> */}
          </form>
        )}
      </Form>
    );
    else{
      return (<section class="post"><h2>Thank you!</h2><p>Your RSVP has been received and you should receive a confirmation email shortly.</p></section>);
    }
  }
}
