import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"

import { render } from "react-dom";
import { Field } from "react-final-form";
import Wizard from "../components/wizard";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const onSubmit = async values => {
  await sleep(300);
  window.alert(JSON.stringify(values, 0, 2));
};

const Error = ({ name }) => (
    <Field
      name={name}
      subscribe={{ touched: true, error: true }}
      render={({ meta: { touched, error } }) =>
        touched && error ? <span>{error}</span> : null
      }
    />
  );

  const Condition = ({ when, is, children }) => (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => (value === is ? children : null)}
    </Field>
  );

const required = value => (value ? undefined : "Required");

const RsvpPage = (props) => (
  <Layout location={props.location}>
        {/* Post */}
        <section className="post">
        <Wizard
            initialValues={{ }}
            onSubmit={onSubmit}
            >
            <Wizard.Page
                validate={values => {
                const errors = {};
                if (values.accessCode === "hotdog") {
                } else if (values.accessCode === "peppercat"){
                    delete values.bringingGuest;
                    delete values.guest;
                }
                else{
                    errors.accessCode = "Invalid access code."
                    delete values.bringingGuest;
                    delete values.guest;
                }
                return errors;
                }}
            >
                <div>
                <label>Access Code</label>
                <Field
                    name="accessCode"
                    component="input"
                    type="text"
                    placeholder="Access Code"
                    validate={required}
                />
                <div className="error"><Error name="accessCode"/></div>
                </div>
                <div>
                <label>Email</label>
                <Field
                    name="email"
                    component="input"
                    type="email"
                    placeholder="Email"
                    validate={required}
                />
                <div className="error"><Error name="email"/></div>
                </div>
            </Wizard.Page>
            <Wizard.Page
                validate={values => {
                    const errors = {};
                    if (!values.attending) {
                        errors.attending = "Required";
                    }
                    if(!values.bringingGuest)
                    {
                        delete values.guest;
                    }
                    return errors;
                    }}
                    >
                <label>Name</label>
                <div className="row gtr-uniform">
                <div className="col-6 col-12-xsmall">
                    <Field
                        name="firstName"
                        component="input"
                        type="text"
                        placeholder="First Name"
                        validate={required}
                    />
                    <div className="error"><Error name="firstName"/></div>
                </div>
                    <div className="col-6 col-12-xsmall">
                    <Field
                        name="lastName"
                        component="input"
                        type="text"
                        placeholder="Last Name"
                        validate={required}
                    />
                    <div className="error"><Error name="lastName"/></div>

                    </div>
                </div>
                <div>
                <label>Attending</label>
                <Field name="attending" component="select" required>
                    <option />
                    <option value="yes">I'll be there</option>
                    <option value='no'>Can't make it</option>
                </Field>
                <div className="error"><Error name="attending"/></div>
                </div>
                <Condition when="attending" is="yes">
                <div>
                <label>Meal Preference</label>
                <Field name="mealPref" component="select" required>
                    <option />
                    <option value="beef">Beef</option>
                    <option value="chicken">Chicken</option>
                    <option value="vegetarian">Vegetarian </option>
                </Field>
                <div className="error"><Error name="mealPref"/></div>
                </div>
                <div>
                <label>Dietary Restrictions</label>
                <Field
                    name="dietaryRestrictions"
                    component="input"
                    type="text"
                    placeholder="Dietary Restrictions"
                />
                </div>  
                <Condition when="accessCode" is="hotdog">
                <label>Guest</label>
                <div>
                    <Field name="bringingGuest" id="bringingGuest" component="input" type="checkbox" />
                    <label htmlFor="bringingGuest">Bringing Guest</label>
                </div>
                <Condition when="bringingGuest" is={true}>
                <div className="guest">
                <label>Name</label>
                <div className="row gtr-uniform">
                <div className="col-6 col-12-xsmall">
                    <Field
                        name="guest.firstName"
                        component="input"
                        type="text"
                        placeholder="First Name"
                        validate={required}
                    />
                    <div className="error"><Error name="guest.firstName"/></div>
                </div>
                    <div className="col-6 col-12-xsmall">
                    <Field
                        name="guest.lastName"
                        component="input"
                        type="text"
                        placeholder="Last Name"
                        validate={required}
                    />
                    <div className="error"><Error name="guest.lastName"/></div>

                    </div>
                </div>
                <div>
                <label>Email</label>
                <Field
                    name="guest.email"
                    component="input"
                    type="email"
                    placeholder="Email"
                />
                <div className="error"><Error name="guest.email"/></div>
                </div>
                <div>
                <label>Meal Preference</label>
                <Field name="guest.mealPref" component="select" required>
                    <option />
                    <option value="beef">Beef</option>
                    <option value="chicken">Chicken</option>
                    <option value="vegetarian">Vegetarian </option>
                </Field>
                <div className="error"><Error name="guest.mealPref"/></div>
                </div>
                <div>
                <label>Dietary Restrictions</label>
                <Field
                    name="guest.dietaryRestrictions"
                    component="input"
                    type="text"
                    placeholder="Dietary Restrictions"
                />
                </div>  
                </div>

                </Condition>
                </Condition> 
                </Condition>        
                <div>
                <label>Comment</label>
                <Field name="comment" component="textarea" placeholder="Notes" />
                </div>
            </Wizard.Page>
            </Wizard>
        </section>
  </Layout>
)

export default RsvpPage
